<!-- 教务 -- 考勤管理 -- 教师考勤记录 -- 列表 -->
<template>
  <list-template
      :loading="loading"
      :total="total"
      :current-page="page"
      :table-data="tableData"
      :table-config="tableConfig"
      :search-config="searchConfig"
      @onChangePage="changeCurrentPage"
      @onSearch="onSearch"
      @onHandle="tableHandle"
      ref="table" is-download
      @onReset="
      search = null
    "
      @onExportData="onDownload"
  >
    <div slot="title" style="color: #333; margin: 20rem 0">
      实际出勤课时数: {{ attendance }}
      <span class="mr-20"/>
      迟到次数: {{ late }}
      <span class="mr-20"/>
      未打卡次数: {{ dontCord }}
      <span class="mr-20"/>
      代课次数: {{ proxy }}
    </div>
  </list-template>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex'
import { getDayFromNow } from '@/utils'

const dateArr = [getDayFromNow(-2), getDayFromNow(0)]
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  computed: {
    ...mapState(['page']),
    ...mapGetters(['userInfo'])
  },
  data() {
    return {
      loading: true,
      // 搜索框的选中数据
      search: null,
      // 接口需要的搜索数据
      searchVal: null,
      // 数据总量
      total: 0,
      // 实际出勤课时数
      attendance: 0,
      // 迟到次数
      late: 0,
      // 未打卡次数
      dontCord: 0,
      // 代课次数
      proxy: 0,
      searchConfig: [
        {
          prop: 'teacher_name',
          placeholder: '搜索教师姓名'
        },
        {
          prop: 'punch_in_status',
          placeholder: '选择打卡状态',
          tag: 'select',
          default: -1,
          options: [
            {
              label: '全部',
              value: -1
            },
            {
              label: '正常',
              value: 1
            },
            {
              label: '迟到',
              value: 2
            },
            {
              label: '未打卡',
              value: 0
            },
            {
              label: '旷课',
              value: 3
            }
          ]
        },
        {
          prop: 'date_from_to',
          tag: 'datePicker',
          default: dateArr,
          property: {
            size: 'small',
            style: 'margin-right:20rem;',
            type: 'daterange',
            valueFormat: 'yyyy-MM-dd',
            startPlaceholder: '开始日期',
            endPlaceholder: '结束日期'
          }
        },
        {
          prop: 'school_id',
          placeholder: '请选择校区',
          tag: 'select',
          options: []
        },
        {
          prop: 'type',
          placeholder: '请选择考勤类别',
          tag: 'select',
          options: [],
          default: -1
        },

        {
          prop: 'is_agent',
          placeholder: '是否为代课',
          tag: 'select',
          default: -1,
          options: [
            {
              value: -1,
              label: '全部'
            },
            {
              value: 0,
              label: '否'
            },
            {
              value: 1,
              label: '是'
            }
          ]
        }
      ],
      tableData: [],
      tableConfig: [
        {width: "80rem", label: '序号', type: 'number'},
        {
          prop: 'punch_in_status_preview',
          label: '打卡状态',
          width: 90,
          render(val) {
            const color = val.punch_in_status_preview === '正常' ? 'green' : val.punch_in_status_preview === '迟到' ? 'red' : '#666'
            return `<span style="color:${color}">${val.punch_in_status_preview}</span>`
          }
        },
        {
          prop: 'teacher_name',
          label: '教师姓名',
          width: 90
        },
        {
          prop: 'check_date_preview',
          label: '日期(星期)',
          width: 150
        },
        {
          prop: 'section',
          label: '课节',
          // showOverflowTooltip: false,
          // width: 260
        },
        {
          prop: 'class_name',
          label: '课程名称',
          // showOverflowTooltip: false,
          // width: 260
        },
        {
          prop: 'punch_in_time_preview',
          label: '打卡时间',
          width: 120
        },
        {
          prop: 'be_late_time',
          label: '迟到时间',
          width: 120
        },
        // {
        //   prop: 'th_num',
        //   label: '工号'
        // },
        {
          prop: 'is_agent',
          label: '是否为代课',
          width: 110,
          render(row) {
            let res = parseInt(row.is_agent)
            return !!res ? '是' : '否'
          }
        },
        {
          prop: 'school_name',
          label: '校区'
        },
        {
          label: '操作',
          handle: true,
          width: '80',
          buttons: [{ type:"view", text:"详情" }]
        }
      ]
    }
  },
  mounted() {
    this.$store.commit('setPage', 1)
    for (let k in this.userInfo.school_arr) {
      this.searchConfig[3].options.push({
        label: this.userInfo.school_arr[k],
        value: k
      })
    }
    // 获取数据
    this.onSearch({date_from_to: dateArr})

    this.$_axios2('api/attendance/teacher-attendance/attendance-type-options').then(res => {
      this.searchConfig[4].options = res.data.data
      this.searchConfig[4].options.unshift({
        label: '全部考勤',
        value: -1
      })
    })
  },
  methods: {
    ...mapMutations(['setPage']),
    // 获取接口所需要的数据
    getSearchVal(val) {
      let search = null
      if (!!val) {
        search = {}
        // 判定数据不为null , 并且不为-1(全部)
        let judge = val => val != null && val !== -1
        let {teacher_name, punch_in_status, date_from_to, school_id, is_agent, type} = val
        teacher_name ? (search.teacher_name = teacher_name) : null
        judge(punch_in_status) ? (search.punch_in_status = punch_in_status) : null
        date_from_to ? (search.date_from_to = date_from_to.join('~')) : null
        school_id ? (search.school_id = school_id) : null
        judge(is_agent) ? (search.is_agent = is_agent) : null
        judge(type) ? (search.type = type) : null
      }
      return search
    },
    // 获取列表基础数据
    getData() {
      this.loading = true
      this.$_axios2('api/attendance/teacher-attendance/list', {params: {...this.searchVal, page: this.page}})
          .then(res => {
            this.tableData = res.data.data.list
            this.total = res.data.data.page.total
            this.attendance = res.data.data.summary.attend_num
            this.late = res.data.data.summary.belate_num
            this.dontCord = res.data.data.summary.punch_in_num
            this.proxy = res.data.data.summary.agent_num
          })
          .catch().finally(() => {
        this.loading = false
      })
    },
    changeCurrentPage(e) {
      this.setPage(e)
      this.getData()
    },
    onSearch(val) {
      this.setPage(1)
      this.search = val
      this.searchVal = this.getSearchVal(val)
      this.getData()
    },


    tableHandle(row, text, index) {
      this.$router.push('./details?id=' + row.id)
    },

    onDownload() {
      this.$_axios2('api/attendance/teacher-attendance/list-export', {params: {...this.searchVal}}).then(res => {
        console.log(res)
        if (!res.data.status) this.$tools.download('教师考勤记录', res.data)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
